<template>
    <VisitorPage>
        <section>
            <router-link class="back-link mb-4" :to="{name: 'login'}">
                &lt; {{ $t('Back') }}
            </router-link>
            <div class="container">
                <h2 class="typography--h1-bold mb-2">{{ $t('Forgot your password?') }}</h2>
                <form v-if="!success" ref="form" @submit.prevent="submit()">
                    <b-field :label="$t('Enter your email here:')">
                        <b-input v-model="email" autocomplete="email"
                                 required
                                 name="email"
                                 type="email"
                                 :validation-message="$t('Please fill out this field')" />
                    </b-field>

                    <b-message v-if="errors" type="is-danger" has-icon>
                        <ul>
                            <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                        </ul>
                    </b-message>

                    <div class="buttons" style="justify-content: flex-end">
                        <b-button class="is-primary" native-type="submit">
                            {{ $t('Send password reset link') }}
                        </b-button>
                    </div>
                </form>

                <b-message v-else type="is-info message-like">
                    <BulbIcon />
                    {{ $t('Password reset requested successfully.') }}
                    <br>
                    {{ $t('You will receive an email with the link shortly.') }}
                </b-message>
            </div>
        </section>
    </VisitorPage>
</template>

<script>
    import { NotificationMixin } from '@/config/mixins'
    import ApiService from '@/services/api.service'
    import VisitorPage from '@/components/VisitorPage'
    import BulbIcon from '@/components/icons/Bulb'

    export default {
        name: 'ForgotPassword',
        components: { VisitorPage, BulbIcon },
        mixins: [ NotificationMixin ],
        data() {
            return {
                email: null,
                errors: null,
                success: false
            }
        },
        methods: {
            async submit() {
                this.errors = null
                if (!this.$refs.form.reportValidity())
                    return

                try {
                    await ApiService.post(
                        `/user/company_user/request_password_reset/`,
                        { email: this.email }
                    )
                    this.success = true
                } catch (e) {
                    if (e.response && e.response.data && e.response.data.password)
                        this.errors = e.response.data.password
                    else
                        this.notifyError(e.response ? e.response.data || e.response : e)
                }
            }
        }
    }
</script>
